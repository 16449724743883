<template>
  <div>
   <base-header class="pb-1">
       <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Cadastro de Cliente</h6>
        </div>
      </div>
    </base-header>

   <div class="container-fluid mt--6">
    <form class="needs-validation" @submit.prevent="salvarHolder">
        <card>
            <h3 slot="header" class="mb-0">Dados Básicos</h3>
            <div class="form-row">
                <div class="col-md-4">
                    <base-input 
                      label="CPF/CNPJ"
                      name="cpfcnpj"
                      placeholder="CPF/CNPJ"
                      required
                      v-model="holder.cpfCnpj"
                      v-mask="['###.###.###-##', '##.###.###/####-##']">
                    </base-input>
                </div>
                <div class="col-md-5">
                    <base-input label="Nome Completo / Razão Social"
                      name="nomecompleto"
                      placeholder="Nome Completo / Razão Social"
                      required
                      v-model="holder.name">
                    </base-input>
                </div>
                <div class="col-md-3" v-if="holder.cpfCnpj.length == 11">
                    <base-input label="Data de Nascimento"
                      name="datadenascimento"
                      placeholder="Data de Nascimento"
                      v-model="holder.dateOfBirth"
                      v-mask="'##/##/####'">
                    </base-input>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-3">
                    <base-input label="E-mail"
                      name="email"
                      placeholder="E-mail"
                      v-model="holder.email" 
                      type="email">
                    </base-input>
                </div>
                <div class="col-md-3">
                    <base-input label="Tel Celular"
                      name="celular"
                      placeholder="Tel Celular"
                      required
                      v-model="holder.cellPhone"
                       v-mask="['(##)#####-####']">
                    </base-input>
                </div>
                <div class="col-md-3">
                    <base-input label="Tel Res/Com"
                      name="homePhone"
                      placeholder="Tel Res/Com"
                      v-model="holder.homePhone"
                      v-mask="['(##)####-####','(##)#####-####']">
                    </base-input>
                </div>
                <div class="col-md-3">
                  <label class="form-control-label">
                    Sexo
                  </label>
                  <select class="form-control" v-model="holder.sex">
                    <option value="MALE">Masculino</option>
                    <option value="FEMALE">Feminino</option>
                    <option value="NI">Não informado</option>
                  </select>
                </div>
            </div>
        </card>

        <div class="row">
           <!-- Endereco -->
          <div class="col-lg-12">
            <div class="card-wrapper">
                <card>
                    <h3 slot="header" class="mb-0">Endereço</h3>
                     <div class="row">
                      <div class="col-md-6">
                          <base-input 
                            append-icon="fas fa-map-marker" 
                            name="cep"
                            label="CEP"
                            placeholder="CEP"
                            required
                            v-model="holder.zipcode"
                            v-mask="'########'"
                            @blur="validateZipcode">
                          </base-input>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-md-12">
                          <base-input 
                            label="Endereço"
                            name="endereco"
                            placeholder="Endereço"
                            required
                            v-model="holder.address">
                          </base-input>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-md-4">
                          <base-input 
                            label="Número"
                            name="numero"
                            required
                            placeholder="Número"
                            v-model="holder.number">
                          </base-input>
                      </div>
                      <div class="col-md-8">
                          <base-input 
                            label="Complemento"
                            name="complemento"
                            placeholder="Complemento"
                            v-model="holder.complementAddress">
                          </base-input>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-md-4">
                          <base-input 
                            label="UF"
                            name="uf"
                            placeholder="UF"
                            required
                            v-model="holder.uf">
                          </base-input>
                      </div>
                      <div class="col-md-8">
                          <base-input 
                            label="Cidade"
                            name="cidade"
                            placeholder="Cidade"
                            required
                            v-model="holder.city">
                          </base-input>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-md-12">
                          <base-input 
                            label="Bairro"
                            name="bairro"
                            placeholder="Bairro"
                            required
                            v-model="holder.neighborhood">
                          </base-input>
                      </div>
                    </div>  
                </card>  
            </div>
          </div>
        </div>

        <div class="col-md-12 text-center mb-3">
            <base-button type="primary" native-type="submit" :disabled="beginSaveProcess">Salvar Cliente</base-button>
        </div>
        
    </form>   
   </div> <!--- fecha conteudo -->

  </div>
</template>
<script>
export default {
  props : ['holderId', 'subscriptionId'],
  components: {
  },
  created(){
    this.loadHolder()
  },
  data() {
    return {
        beginSaveProcess : false,
        holder: {
            id: null,  
            cpfCnpj: '',
            name: '',
            dateOfBirth: '',
            sex: 'MALE',
            email: '',
            cellPhone: '',
            homePhone: '',
            zipcode: '',
            address: '',
            complementAddress: '',
            uf: '',
            city: '',
            neighborhood: '', 
            isHolder: true  
        }
    };
  },
  methods: {
    salvarHolder() {
        try {
            this.$validator.validateAll()
        } finally {
            this.validated = true;
        }
        if(this.validated){
          this.beginSaveProcess = true
          this.$clubApi.post('/holder', this.holder)
          .then(() => {
             this.$notify({type: 'success', message: "Dados salvos com sucesso."})
             if(this.subscriptionId != null){
               location.href = '/assinatura/'+this.subscriptionId;
             }
          }).catch((error) => {
             this.$notify({type: 'warning', message: error.response.data.msg})
          }).finally(() =>{
              this.beginSaveProcess = false
              NProgress.done() 
          })
        }
    },
    loadHolder(){
         this.$clubApi.get('/holder/'+this.holderId)
        .then((response) => {
           this.holder = response.data.object
           this.holder.cpfCnpj = this.holder.cpfCnpjFmt;
           this.holder.isHolder = true;
        }) .catch(() => {
           this.$notify({type: 'warning', message: 'Erro ao carregar dados do cliente.'})
        }).finally(() =>{
            NProgress.done() 
        })
    },
    validateZipcode(){
        var zipcode = this.holder.zipcode.replace("-","")
        this.$clubApi.get('/utils/zipcode/'+ zipcode)
        .then((response) => {
            this.holder.address = response.data.object.logradouro;
            this.holder.uf = response.data.object.uf;
            this.holder.city = response.data.object.localidade;
            this.holder.neighborhood = response.data.object.bairro;
        }).finally(() =>{
            NProgress.done() 
        })
    }
  }
};
</script>
<style></style>
